<div class="login-mainwrapper">

    <div class="loginbg">
      <div class="login-background1"></div>
      <div class="login-background2"></div>
      <div class="login-background3"></div>
      <div class="login-background4"></div>
      <div class="login-background5"></div>
      <div class="login-background6"></div>
    </div>

    <div class="login-design1"></div>
    <div class="login-design2"></div>
    <div class="login-design3"></div>
    <div class="login-design4"></div>

    <div class="login-subwrapper">
        <div class="loginform-wrapper">
            <div>
                <div class="login_body">
                    <div class="login_main">


                      <div class="login_form">

                        <form class="form_wrp" autocomplete="off" name="loginForm"   [formGroup]="loginForm">
                          
                          <p> <strong> WELCOME TO</strong> Practicepresence Cancer</p>

                          <div class="email_cls">

                            <div class="iconwrapper"><i class="fa fa-user-circle-o" aria-hidden="true"></i></div>

                            <mat-form-field class="login-full-width">
                              <input matInput placeholder="Email" #email name="email" formControlName="email" required class="form_wrp-field" autocomplete="off" required (keydown)="handleInputChange($event)"/>
                            </mat-form-field>

                          </div>

                          <mat-error *ngIf="errors?.email">{{errors.email}}</mat-error>
                  
                          <div class="pass_cls">
                  
                            <mat-form-field class="login-full-width">

                              <input matInput #password [type]="hide ? 'password' : 'text' " type="password" placeholder="Password" name="password" formControlName="password" required class="form_wrp-field" autocomplete="off" required (keydown)="handleInputChange($event)"/>
                            
                              <button mat-icon-button matSuffix type="button" (click)="handlevisibl($event)"  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
                              </button>

                            </mat-form-field>
                        
                          </div>

                          <mat-error *ngIf="errors?.password">{{errors.password}}</mat-error>
                          <h1> <a [routerLink]="['/forget-password']">Forgot Password?</a></h1>

                        </form>

                        <button mat-raised-button class="submit"  [disabled]="loader" [ngClass]="loader==true? 'disabled':'' " (click)="login()" >Login</button>
                        <mat-progress-bar *ngIf="loader" mode="indeterminate" class="loader_wrp"></mat-progress-bar>


                      </div>

                    </div>
                  </div>
            </div>
        </div>
    </div>
</div>
