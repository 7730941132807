<div class="customer_preview_modal" >
    <h2 class="customer_preview_modal_heading">Social Advo Post</h2>
    <ul class="customer_preview_modal_listing">
      
      
      <!-- <li >
        <h3>Video(S):</h3>
      <span *ngFor="let video of data.video_gallery"><video
          [src]="this._sanitizer.bypassSecurityTrustResourceUrl(video.videoUrl)" controls></video></span>
      </li> -->
  
      <li>
        <span class="title image_title">Audio :</span>
        <span class="audio_wraper">
          <section *ngFor="let audio of data.audio">
  
            <ng-container *ngIf="audio.fileservername;then audio_first else audio_second "></ng-container>
            <ng-template #audio_first>
              <audio controls>
                <source [src]="'https://d37pbainmkhx6o.cloudfront.net/'+audio.baseurl+audio.fileservername" >
              
              </audio>
            </ng-template>
            <ng-template #audio_second>
             N/A
            </ng-template>
  
           
          </section>
        </span>
      </li>
  
      <!-- <li>
        <span class="title">Created On :</span>
        <span>{{data?.created_on ? create_on :"N/A"}}</span>
      </li>
  
      <li>
        <span class="title">Updated On :</span>
        <span>{{data?.updated_on ? updated_on :"N/A"}}</span>
      </li> -->
  
  
    </ul>
  
  
  
    <button class="close_button" (click)="closeDialog()">X</button>
  
  </div>
  