import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiservicesService } from 'src/app/services/apiservices.service';

@Component({
  selector: 'app-location-add-edit',
  templateUrl: './location-add-edit.component.html',
  styleUrls: ['./location-add-edit.component.css']
})
export class LocationAddEditComponent {


  public formConfigaration:any = {}
  public formfieldrefresh: any = null;
  public formfieldrefreshdata: any = null;
  public loader:boolean = false
  public paramsId :any 
  public autocompliteValue:any
  public states_array:any=[]
  public webpage_array:any = []
  public is_edit:boolean = false
  emailregex: RegExp =/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  constructor(private apiservice: ApiservicesService,public router:Router,public activateRoute: ActivatedRoute){}


  ngOnInit(){

    this.paramsId = this.activateRoute.snapshot.params["_id"]

    this.fetchStateData()
    this.fetchWebsiteData()
    if(this.paramsId){
      this.activateRoute.data.subscribe({
        next:(responce:any)=>{
          this.autocompliteValue = responce.data.results[0]
          this.is_edit = true
        },
        error:(err)=>{
          this.is_edit = true
        },
      })

    }


    




    this.setConfigetrFuntion()
  }


  fetchStateData(){
    this.apiservice.getHttpData('user/states').subscribe({
      next:(value) =>{
        let array = value.results.res
        for(let i = 0; i < array.length;i++){
          this.states_array[i] = {'name':array[i].name,'val':array[i].abbreviation,'_id':array[i]._id,}
        }
      },
      error(err) {
        
      },
    })
  }

  fetchWebsiteData(){
    this.apiservice.getHttpData('website-management/web-name-fetch').subscribe({
      next:(value) =>{
        let array = value.results
        for(let i = 0; i < array.length;i++){
          this.webpage_array[i] = {'name':array[i].val,'val':array[i].key,'_id':array[i].key,}
        }
      },
      error(err) {
        
      },
    })
  }




  setConfigetrFuntion(){
    this.formConfigaration =
    {
      // from start
      successmessage: 'Added Successfully !!',
      submittext: this.is_edit?'update':'Submit',
      apiUrl: this.apiservice.baseUrl,
      resettext: 'Reset',
      hidereset: this.is_edit?true:false,
      canceltext: 'Cancel',
      jwttoken: '',
      fields: [
        {
          label:'Name',
          name:'name',
          value:this.is_edit ? this.autocompliteValue.name:'',
          type:'text',
          classname: 'aaa',
          validations: [{ rule: 'required', message: 'First Name is Required' },],
        },
        {
          label: 'Email',
          name: 'email',
          value: this.is_edit ? this.autocompliteValue.email:"",
          type: 'email',
          validations: [
            { rule: 'required', message: 'Email is Required' },
            { rule: 'pattern', value: this.emailregex, message: "Must be a valid Email" },
          ],
        },
        {
          label: 'Phone',
          name: 'phone',
          value: this.is_edit ? this.autocompliteValue.phone:"",
          type: 'numberformat',
          formatflag: true,
          validations: [
            { rule: 'required', message: 'Phone is Required' },
            { rule: 'minLength', value: 14 ,message: "Formating Phone Number min 10"},
          ],
        },
        {
          label:'Website Id',
          name:'website_id',
          value:this.is_edit ? this.autocompliteValue.website_id:'',
          val:this.webpage_array,
          multiple: true,
          type:'select',
          classname: 'aaa',
          validations: [{ rule: 'required', message: 'First Name is Required' },],
        },

        {
          label:'State',
          name:'state',
          value:this.is_edit ? this.autocompliteValue.state:'',
          val:this.states_array,
          type:'select',
          classname: 'aaa',
          validations: [{ rule: 'required', message: 'First Name is Required' },],
        },
        {
          label:'City',
          name:'city',
          value:this.is_edit ? this.autocompliteValue.city:'',
          type:'text',
          classname: 'aaa',
          validations: [{ rule: 'required', message: 'First Name is Required' },],
        },
        {
          label:'Zip',
          name:'zip',
          value:this.is_edit ? this.autocompliteValue.zip:'',
          type:'number',
          classname: 'aaa',
          validations: [{ rule: 'required', message: 'First Name is Required' },],
        },
        {
          label: 'Status',
          name: 'status',
          type: 'select',
          val: [
            { val: 1, name: 'Active' },
            { val: 0, name: 'Inactive' }
          ],
          value:this.is_edit ? this.autocompliteValue.status:'',
        },
        {
          label:'Address',
          name:'Address',
          value:this.is_edit ? this.autocompliteValue.Address:'',
          type:'textarea',
          classname: 'aaa',
          validations: [{ rule: 'required', message: 'First Name is Required' },],
        },
      ]
    }
  }




  listenFormFieldChange(val:any){

    console.log();
    

    if(val.field === 'fromsubmit' && val.fieldval === 'success'){

      this.loader=true

      let formVal = val.fromval

      if(this.is_edit){
        formVal = {...formVal,'_id':this.autocompliteValue._id}
      }


      this.apiservice.getHttpDataPost(this.is_edit?'loation/location-update':'loation/location-add', formVal ).subscribe({
        next:(value)=> {
            this.locationPageNavigationFuntion()
            this.loader = false
        },
        error:(err)=> {
          this.loader = false
        },
      })
    }
    if(val.field === 'formcancel'){
      this.locationPageNavigationFuntion()
    }
    if(val.field && val.field == "formreset"){
      this.formfieldrefreshdata = {
        field: "description",
        value: '',
      }
    }
  }




  locationPageNavigationFuntion(){
    this.router.navigateByUrl('/location')
  }


}
