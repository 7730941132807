import { Component, Inject, Optional } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiservicesService } from 'src/app/services/apiservices.service';
import { SocialadvoaccountserviceService } from '../socialadvoaccountservice.service';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from 'src/app/shared-module/shared-module/shared-module.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-connect-accounts',
  templateUrl: './connect-accounts.component.html',
  styleUrls: ['./connect-accounts.component.css', '../../../social-advo-dashboard/dashboard/dashboard.component.css'],
})
export class ConnectAccountsComponent {
  constructor(
    public router: Router,
    public cookieService: CookieService,
    public activatedRoute: ActivatedRoute,
    private apiService: ApiservicesService,
    public dialog: MatDialog,
    public matSnackBar: MatSnackBar,
    public accountConnectService: SocialadvoaccountserviceService
  ) {}

  private cookieData: any;

  public linkedAccountData: any = [];

  ngOnInit() {
    this.cookieData = JSON.parse(this.cookieService.get('login_user_details'));
    console.log('cookieData', this.cookieData);

    let detaObj = {
      user_id: this.cookieData.userinfo._id,
    };

    this.apiService
      .getHttpDataPost('social-advo/social-accounts', detaObj)
      .subscribe((response) => {
        console.log('response llll=====>', response);
        if (response.status == 'success') {
          this.linkedAccountData =
            this.accountConnectService.generateProfileDataSet(
              response.results.res
            );
        }
        console.log('res llll=====>', this.linkedAccountData);
      });
  }

  async deleteProfile(val: any) {
    console.log('val=============>', val);

    const response = await this.revokeAccount({ _id: val });
    if (response == 'success') {
      this.apiService
        .getHttpDataPost('social-advo/social-accounts', {
          user_id: this.cookieData.userinfo._id,
        })
        .subscribe((response: any) => {
          console.log('val====>', response);
          if (response.status == 'success') {
            this.linkedAccountData =
              this.accountConnectService.generateProfileDataSet(
                response.results.res
              );
            this.matSnackBar.open('Account revoked successfully', 'Ok', {
              duration: 3000,
            });
          }
        });
    }
  }

  openConnectAccModal() {
    this.dialog.open(ConnectAccount, {
      data: null,
    });
  }

  async revokeAccount(requestObject: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService
        .getHttpDataPost('social-advo/revoke-social-account', requestObject)
        .subscribe({
          next: (response: any) => {
            console.log('val====>', response);
            resolve('success');
          },
          error: (error) => {
            this.matSnackBar.open(error.message, 'Ok', {
              duration: 3000,
            });
            reject('error');
          },
        });
    });
  }
}

@Component({
  selector: 'ConnectAccount',
  templateUrl: '../../../social-advo-dashboard/dashboard/connectaccount.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, SharedModule, CommonModule],
})
export class ConnectAccount {
  public modaldata: any = null;

  public cookieData: any = {};

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<ConnectAccount>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiservicesService,
    public matSnackBar: MatSnackBar,
    public cookieService: CookieService
  ) {}

  ngOnInit() {
    if (this.cookieService) {
      this.cookieData = JSON.parse(
        this.cookieService.get('login_user_details')
      );
    }
    console.log('this.cookieService->', this.cookieData);
  }

  navigateAuth(val: any) {
    console.log('val---------->', val);

    if (val == 'facebook') {
      window.location.href = `https://www.facebook.com/v10.0/dialog/oauth?client_id=367135576372388&redirect_uri=https://nfblg0aig0.execute-api.us-east-1.amazonaws.com/dev/social-advo/get-facebook-verification-code&state={%22user_id%22:%22${this.cookieData.userinfo._id}%22,%22redirection_path%22:%22https://backoffice.screenforcancer.life/social-advo-dashboard%22}&scope=pages_manage_posts,pages_read_engagement`;
    }
    if (val == 'twitter') {
      window.location.href = `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=TGkxS3RJVktDc2F5TlJCbGlqdzk6MTpjaQ&client_secret=1AHe836VOulZNds_DrN80rApb4rgxqCUR9_V9q5YkAZv9O1iVI&redirect_uri=https://nfblg0aig0.execute-api.us-east-1.amazonaws.com/dev/social-advo/get-twitter-verification-code&scope=tweet.write%20tweet.read%20users.read%20offline.access&state=${this.cookieData.userinfo._id}%20https://backoffice.screenforcancer.life/social-advo-dashboard&code_challenge=challenge&code_challenge_method=plain`;
    }

    if (val == 'lnkdn') {
      window.location.href = `https://linkedin.com/oauth/v2/authorization?response_type=code&client_id=77clwm3fvwhdch&redirect_uri=https://nfblg0aig0.execute-api.us-east-1.amazonaws.com/dev/social-advo/get-linkedin-verification-code&state={%22user_id%22:%22${this.cookieData.userinfo._id}%22,%22redirection_path%22:%22https://backoffice.screenforcancer.life/social-advo-dashboard%22}&scope=profile%20email%20w_member_social%20openid`;
    }
    console.log('this.router.url==>', window.location.href);
  }
}
