<div class="top-header">
  <div class="left-menu">
    <div class="logo-wrapper">
    </div>
  </div>
  <div class="right-menu">


    <div class="top-btn user">
      <i class="fa fa-user-circle-o" aria-hidden="true"></i>
    </div>

    <h3 style="text-transform: capitalize;">{{login_user_details?.userinfo?.name}}</h3>

    <div class="top-btn" [matMenuTriggerFor]="rightdownmenu">
      <i class="fa fa-angle-down" aria-hidden="true"></i>
    </div>

    <mat-menu #rightdownmenu="matMenu">

      <button mat-menu-item (click)="myAccount()">
        <i class="fa fa-user-circle-o" aria-hidden="true"></i> My Account
      </button>

      <button mat-menu-item (click)="changePass()">
        <i class="fa fa-unlock-alt" aria-hidden="true"></i> Change Password
      </button>

      <button mat-menu-item (click)="logout()">
        <i class="fa fa-sign-out" aria-hidden="true"></i> Logout
      </button>
      
    </mat-menu>
  </div>
</div>


<div class="navbar-mainwrapper">
  <div class="navbar-subwrapper">
    <div class="nav-flx-wrp">

      <div class="tggl-menu">
        <button (click)="burgerMenuClick()"><mat-icon>menu</mat-icon></button>
      </div>

      <!-- ////////////////////admin/////////////////////////////// -->
      <div class="nav-listwrapper" *ngIf="adminFlag">
        <ul>
          <li><a [ngClass]="currentPath() == '/dashboard/main' || currentPath() == '/dashboard' ? 'active' : ''" (click)="dashboard()">Dashboard</a></li>
          <li><a [ngClass]="currentPath() == '/users' ||currentPath()=='/users/user-add' ||currentPath() == '/users' ? 'active' : ''" (click)="users()">Users</a></li>

          <!-- Bloge  -->
          <li><a [matMenuTriggerFor]="blog" [ngClass]="currentPath() == '/blog-management/blog-add' || currentPath() == '/blog-management' || currentPath() == '/blog-management/blog-category' || currentPath() == '/blog-management/blog-category-add' || currentPath() == '/blog-management/bloge-category-edit' ? 'active' : ''" >Blog <i class="downarrow"></i></a></li>
          <mat-menu #blog="matMenu" class="drop-down">
            <li> <a [ngClass]=" currentPath() == '/blog-management' || currentPath() == '/blog-management/blog-add' ? 'active' : ''" (click)="bloglist()">Blog Management</a> </li>
            <li> <a [ngClass]=" currentPath() == '/blog-management/blog-category' || currentPath() == '/blog-management/blog-category-add' ? 'active' : ''" (click)="blogCategoryRoute()">Blog Category</a> </li>
          </mat-menu>


          <!-- gallery -->
          <li><a [matMenuTriggerFor]="gallery" [ngClass]="currentPath() == '/image-gallery' || currentPath() == '/image-gallery/add' || currentPath() == '/video-gallery' || currentPath() == '/video-gallery/add' || currentPath() == '/video-category' || currentPath() == '/video-category/add' || currentPath() == '/image-category' || currentPath() == '/image-category/add' ? 'active' : ''">Gallery <i class="downarrow"></i></a></li>
          <mat-menu #gallery="matMenu" class="drop-down">
            <li><a [ngClass]="currentPath() == '/image-gallery/add' || currentPath() == '/image-gallery' ? 'active' : ''" (click)="imglist()">Image Gallery</a></li>
            <li><a [ngClass]="currentPath() == '/image-category' || currentPath() == '/image-category/add' ? 'active' : ''" (click)="imageCategory()">Image Category</a></li>
            <li><a [ngClass]="currentPath() == '/video-gallery/add' || currentPath() == '/video-gallery' ? 'active' : ''" (click)="videolist()">Video Gallery</a></li>
            <li><a [ngClass]="currentPath() == '/video-category' || currentPath() == '/video-category/add' ? 'active' : ''" (click)="videoCategorylistRoute()">Video Category</a></li>
          </mat-menu>


          <!-- Calender -->
          <li><a [ngClass]="currentPath() == '/calender' ? 'active' : ''"  (click)="calenderRoute()" >Calender</a></li>


          <!-- Report -->
          <li><a [matMenuTriggerFor]="Report"  [ngClass]="currentPath() == '/report/conversion-report' || currentPath() == '/report/click-report' ? 'active' : ''">Report<i class="downarrow"></i></a></li>
          <mat-menu #Report="matMenu" class="drop-down">
            <li><a [ngClass]="currentPath() == '/report/conversion-report' ? 'active' : ''" (click)="Adminconversioncount()">Conversion-report</a></li>
            <li><a [ngClass]="currentPath() == '/report/click-report' ? 'active' : ''" (click)="Adminclickcount()">Click-report</a></li>
          </mat-menu>


          <!-- testimonial -->
          <li><a [matMenuTriggerFor]="testimonialManagement" [ngClass]="currentPath() == '/testimonial/request-testimonial' || currentPath() == '/testimonial/add-request' ||  currentPath() == '/testimonial/add' || currentPath() == '/testimonial' || currentPath() == '/testimonial/user-testimonial-request-list' ? 'active' : ''"> Manage Testimonial <i class="downarrow"></i> </a></li>
          <mat-menu #testimonialManagement="matMenu" class="drop-down">
            <li><a [ngClass]=" currentPath() == '/testimonial/add' || currentPath() == '/testimonial' ? 'active' : ''" (click)="testimonial()">Testimonial List</a></li>
            <li><a [ngClass]="currentPath() == '/testimonial/request-testimonial' || currentPath() == '/testimonial/add-request' ? 'active' : ''" (click)="testimonialRequest()">Request Testimonial</a></li>
            <li><a [ngClass]="currentPath() == '/testimonial/user-testimonial-request-list' ? 'active' : ''" (click)="goToPage('/testimonial/user-testimonial-request-list')">Testimonial Received</a></li>
          </mat-menu>



          <!-- Team Management -->
          <li><a [ngClass]=" currentPath() == '/team-management' || currentPath() == '/team-management/team-management-add' ? 'active' : ''" (click)="teamManagement()">Team Management</a></li>


          <!-- Social Advo -->
          <li><a [ngClass]="currentPath() == '/post-management/post-list' || currentPath() == '/post-management/post-add' ? 'active' : ''" (click)="goToPage('/post-management/post-list')">Post Management </a></li>
          <!-- <mat-menu #socialAdvoManagement="matMenu" class="drop-down">
            <li><a [ngClass]="currentPath() == '/social-advo' || currentPath() == '/social-advo/social-advo-add' ? 'active' : ''" (click)="goToPage('/social-advo')">Social Advo List</a></li>
            
          </mat-menu> -->










          <!-- Miscellaneous -->
          <li><a [matMenuTriggerFor]="miscellaneous" [ngClass]="currentPath() == '' || currentPath() == '/role-management'|| currentPath() == '/banner-add' || currentPath() == '/banner-edit'||currentPath()=='/role-management/role-add' ||currentPath()=='/banner' || currentPath()=='/banner/add' || currentPath() == '/landing-page' || currentPath() == '/landing-page/landing-page-add' || currentPath() == '/campaign-list' || currentPath() == '/service-management/service-list' || currentPath() == '/service-management/add-service' || currentPath() == '/website-management/website-list' || currentPath() == '/website-management/add-website' ? 'active' : '' ">Miscellaneous<i class="downarrow"></i></a></li>
          <mat-menu #miscellaneous="matMenu" class="drop-down">
            <li><a [ngClass]="currentPath() == '/role-management' || currentPath() == '/role-management/role-add' ? 'active' : ''" (click)="roleManagement()">Role Management</a></li>
            <li><a [ngClass]="currentPath() == '/landing-page' || currentPath() == '/landing-page/landing-page-add' ? 'active' : ''" (click)="opportunity()">Landing Page</a></li>
            <li><a [ngClass]="currentPath() == '/banner' || currentPath()=='/banner/add' ? 'active' : ''" (click)="manageBanner()">Manage Banner</a></li>
            <li><a [ngClass]="currentPath() == '/campaign-list' ? 'active' : ''" (click)="campaign()">Campaign</a></li>
            <!-- service -->
          <li><a [ngClass]="currentPath() == '/service-management/service-list' || currentPath() == '/service-management/add-service' ? 'active' : ''" (click)="goToPage('service-management/service-list')">Service Management</a></li>
            <li><a [ngClass]="currentPath() == '/website-management/website-list' || currentPath() == '/website-management/add-website' ? 'active' : ''" (click)="websiteManagement()">Website Management</a></li>
          </mat-menu>



          <!-- Location -->
          <li><a [ngClass]="currentPath() == '/location' || currentPath() == '/location/add' ? 'active' : ''" (click)="locationManagement()">Location</a></li>
          
          <!-- Contact -->
          <li><a [ngClass]="currentPath() == '/contact' ? 'active' : ''" (click)="contectList()">Contact Us</a></li>

        </ul>




      </div>

      <!-- /////////////////////////////marketing///////////////////////////// -->
      <div class="nav-listwrapper" *ngIf="marketFlag">
        <ul>
          <li>
            <a [ngClass]="currentPath() == '/marketing-dashboard' || currentPath() == '/marketing-dashboard' ? 'active' : ''" (click)="Marketingdashboard()">Dashboard</a>
          </li>

          <li>
            <a [ngClass]="currentPath() == '/marketing-dashboard/users' || currentPath() == '/marketing-dashboard/users' ? 'active' : ''" (click)="Userslist()">Users</a>
          </li>

          <li>
            <a [matMenuTriggerFor]="Report" [ngClass]="currentPath() == '/marketing-dashboard/report/conversion-report' || currentPath() == '/marketing-dashboard/report/click-report' ? 'active' : ''">Report<i class="downarrow"></i></a>
          </li>

          <mat-menu #Report="matMenu" class="drop-down">
            <li><a [ngClass]="currentPath() == '/marketing-dashboard/report/conversion-report' ? 'active' : ''" (click)="Conversioncount()">Conversion-report</a></li>
            <li><a [ngClass]="currentPath() == '/marketing-dashboard/report/click-report' ? 'active' : ''" (click)="Clickcount()">Click-report</a></li>
          </mat-menu>

        </ul>
      </div>


      <!-- /////////////////////////////technician///////////////////////////// -->
      <div class="nav-listwrapper" *ngIf="technicianFlag">
        <ul>
          <li>
            <a [ngClass]="currentPath() == '/technician-dashboard' || currentPath() == '/technician-dashboard' ? 'active' : ''"
              (click)="technicianDashboard()">Dashboard</a>
          </li>
          <li><a [ngClass]="
            calendarPath() == true || currentPath() == '/calendar-management/event-list' ? 'active' : ''
           
          " (click)="calaendar()">Calendar Management</a></li>
        </ul>
      </div>

      <!-- /////////////////////////////technician///////////////////////////// -->


      <!-- << ---------------- SOCIAL ADVO --------------- >> -->

      <div class="nav-listwrapper" *ngIf="socialAdvoFlag">
        <ul>
          <li>
            <a [ngClass]="currentPath() == '/social-advo-dashboard' || currentPath() == '/social-advo-dashboard' ? 'active' : ''"
              (click)="socialAdvoDashboard()">Dashboard</a>
          </li>
        </ul>
      </div>


    </div>


  </div>
</div>