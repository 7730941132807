<div class="delete_wrp">
    <button  class="close-btn-modal" (click)="cancel()"><mat-icon mat-raised-button
        matTooltip="Close"
        matTooltipPosition="below">close</mat-icon></button>  
    <h1>Alert!!</h1>
    <p>Are You Sure You Want To Delete This Record? This Process Can Not Be Undone.</p>
    <div class="delete_sub_wrp">
        <button class="button" (click)="cancel()">Cancel</button>
        <button  class="button" (click)="confirm()">Confirm</button>
    </div>
</div>
