<!-- <div class="mainwrapper"> -->
    <!-- <div class="new-container"> -->

<!-- <div class="subwrapper"> -->
<div class="last_login_wrapper">
    <div class="last_login_sub-title">
      <section class="login_user">
        <i class="fa fa-user-circle-o" aria-hidden="true"></i>
        <h4 style="text-transform: capitalize;"><strong>Welcome: </strong> <span>{{user_login_details.userinfo.name}}</span></h4>
      </section>

      <section class="rep_details" *ngIf="user_login_details.userinfo.user_type.includes('is_marketing_agency') === true">
        <span class="icon_wrapper">
          <i class="fa fa-id-card" aria-hidden="true"></i>
        </span>
        <h4><strong>Marketing Id:</strong></h4>
        <span style="font-size: 24px; word-break: break-all;">{{user_login_details.userinfo.agent_code}}</span>
      </section>
     
      <section class="login_time">
        <i class="fa fa-sign-out" aria-hidden="true"></i>
        <h4><strong>Last Login: </strong> <span>{{last_login}}</span></h4>
      </section>
    </div>
  </div>
<!-- </div> -->
    <!-- </div> -->
<!-- </div> -->