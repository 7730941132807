import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { ApiservicesService } from '../services/apiservices.service';
// import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiservicesService } from 'src/app/services/apiservices.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent {
  public loginForm: FormGroup;
  public loader: boolean = false;
  public chekSubmition:boolean = false
  private userIp: string = 'false';
  hide = true;
  constructor(private cookieService: CookieService, public router: Router, public matSnackBar: MatSnackBar, private apiservice: ApiservicesService) {
    this.loginForm = new FormGroup({
      email: new FormControl('',[Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)]),
      password: new FormControl(''),
    })

    this.cookieService.delete('useremail')
  }

  forgoSubmit() {
    this.chekSubmition = true
    if(this.loginForm.controls['email'].valid){
      this.loader = true
    this.apiservice.getHttpDataPost("user/send-change-password-otp", { email: this.loginForm.value.email }).subscribe({
      next: (response: any) => {
        

        if (response.status === "success") {

          this.cookieService.set('useremail', JSON.stringify({ email: this.loginForm.value.email }))
         
          this.matSnackBar.open(response.message, "Ok", {
            duration: 3000
          });

          setTimeout(() => {
            this.router.navigateByUrl(`/forget-password/forget-otp-verification`);
            this.loader = false
          }, 2000)

        }
      },
      error: (error: any) => {
        this.loader = false
        this.matSnackBar.open(error.message, "Ok", {
          duration: 3000
        });

      }
    })
    }


  }

}
