import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent {

  public datakey: any;
  public datavalue: any;
  public dataOrigin: any;
  public startDate: any;
  public endDate: any;
  public params: any;
  public created_on: any;
  
  public day: any =[] ;
 
  public usersdata: any  ;

  public clickcount: any  ;
  public adminclickcount: any
  public camapaignOrigin: any  ;



  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<PreviewComponent>, public dialog: MatDialog, public activatedRoute: ActivatedRoute, private router: Router, public _sanitizer: DomSanitizer) {
    // this.datakey = this.data.key
    this.dataOrigin = this.data && this.data.origin === "landing_page" ? true : false 
    this.clickcount = this.data && this.data.origin === "click_count" ? true : false 
    this.usersdata = this.data && this.data.origin === "user_data" ? true : false 
    this.adminclickcount = this.data && this.data.origin === "click_count_admin" ? true : false 
    
   
    
    this.camapaignOrigin = this.data && this.data.origin === "campaign_data" ?  true : false 
    
    this.datavalue = this.data.value
    this.startDate = this.datavalue.start_date ? moment(this.datavalue.start_date).format('MMMM Do YYYY') : this.datavalue.start_date
    this.endDate = this.datavalue.end_date ? moment(this.datavalue.end_date).format('MMMM Do YYYY') : this.datavalue.end_date
    this.created_on = this.datavalue.created_on ||  this.datavalue.created_at ? moment(this.datavalue.created_on).format('MMMM Do YYYY') : this.datavalue.created_on

    console.log("dtatadtaa====", this.data, this.datakey, this.datavalue, this.usersdata)
    console.log("aaaaaaaaaa====", this.datavalue)

    console.log("asdqwe",this.clickcount);
  }
  closePreview() {
    this.dialogRef.close()
  }

  ngOnInit() {
    this.params = this.activatedRoute.snapshot.params;

    if (this.data.value.sun === true) {
      this.day.push(" Sunday") 
    }
    if (this.data.value.mon === true) {
    
      this.day.push(" Monday")
    }
    if (this.data.value.tues === true) {
     
      this.day.push(" Tuesday")
    }
    if (this.data.value.wed === true) {
    
      this.day.push(" Wednesday")
    }
    if (this.data.value.thurs === true) {
     
      this.day.push(" Thursday")
    }
    if (this.data.value.fri === true) {
    
      this.day.push(" Friday")

    }
    if (this.data.value.sat === true) {
      
      this.day.push(" Saturday")

    }

    console.log("a===>", this.day);


    // console.log("param=====>",this.router.url);

  }

}
