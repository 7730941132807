import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { buffer, catchError, map, Observable, Subject, Subscription, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ApiservicesService {
  public baseUrl: any = environment.api_url;
  public jwtToken: string = '';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: '',
    }),
  };

  constructor(private http: HttpClient,public matSnackBar: MatSnackBar,) { }

  public getHttpData(url: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + url, this.httpOptions).pipe(
      catchError((error) => {
        // this.openSnackBar();
        console.log('error --->', error);
        return throwError(() => error.error);
      })
    );
  }
  public getHttpFilePost(url: any, body: any): Observable<any> {
    return this.http.post<any>(environment.fileuploadbaseurl + url, JSON.stringify(body), this.httpOptions).pipe(
      catchError((error) => {
        // this.openSnackBar();
        this.matSnackBar.open(error.error.message,'', {
          duration: 3000
        });
            console.log("this is login error",error);
            
        return throwError(()=>error.error);
      })
    )
  }


  public getHttpDataPut(url: any, body: any,httpOptions:any): Observable<any> {
    console.log("body", body,url,httpOptions);
    return this.http.put<any>(url,body, httpOptions).pipe(
      
      catchError((error) => {
        this.matSnackBar.open(error.error.message,'', {
          duration: 3000
        });
            console.log("this is login error",error);
            
        return throwError(()=>error.error);
      })
    )
  }

  public getHttpDataPost(url: any, body: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + url, JSON.stringify(body), this.httpOptions).pipe(
      catchError((error) => {
        // this.openSnackBar();
        this.matSnackBar.open(error.error.message,'', {
          duration: 3000
        });
        
            console.log("this is login error",error);
            
        return throwError(()=>error.error);
      })
    )
  }

  public getHttpDataPostWithoutBaseUrl (url: any,body:any): Observable<any> {
    return this.http.post<any>(url, body).pipe(
      catchError((error) => {
        // this.openSnackBar();
            console.log("this is login error",error);
            
        return throwError(()=>error.error);
      })
    )
  }

  public getHttpCalender(url: any, body: any): Observable<any> {
    return this.http.post<any>(environment.without_base_url + url, JSON.stringify(body), this.httpOptions).pipe(
      catchError((error) => {
        // this.openSnackBar();
        this.matSnackBar.open(error.error.message,'', {
          duration: 3000
        });
            console.log("this is login error",error);
            
        return throwError(()=>error.error);
      })
    )
  }






  public getHttpDataGetWithoutBaseUrl (url: any): Observable<any> {
    return this.http.get<any>(url).pipe(
      catchError((error) => {
        // this.openSnackBar();
            console.log("this is login error",error);
            
        return throwError(()=>error.error);
      })
    )
  }

  public customTestServicePOST (url: any, body:any, header:any): Observable<any> {
   return this.http.post<any>(url, body, {headers:header})

    // .pipe(

    //   catchError((error) => {
    //     // this.openSnackBar();
    //         console.log("this is login error",error);
            
    //     return throwError(()=>error.error);
    //   })
    // )
  }
  public customTestServicePUT (url: any, body:any, header:any): Observable<any> {

    console.log('body=====================++>',body);
    
   return this.http.put(url,body,{headers:header}).pipe(

      catchError((error) => {
        // this.openSnackBar();
            console.log("this is login error",error);
            
        return throwError(()=>error.error);
      })
   )

    // .pipe(

    //   catchError((error) => {
    //     // this.openSnackBar();
    //         console.log("this is login error",error);
            
    //     return throwError(()=>error.error);
    //   })
    // )
  }

}
