import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiservicesService } from 'src/app/services/apiservices.service';

@Component({
  selector: 'app-delete-component',
  templateUrl: './delete-component.component.html',
  styleUrls: ['./delete-component.component.css']
})
export class DeleteComponentComponent {
  public datakey: any;
  public datavalue: any;
  public value: any;
  public extractedData: any = {};
  public created_on: any;
  public updated_on: any
  public created_at: any
  public flag:boolean = false

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<DeleteComponentComponent>, private apiService: ApiservicesService, public matSnackBar: MatSnackBar,) {
    this.datakey = this.data.key
    this.datavalue = this.data.value
    console.log("dtatadtaa====", this.data)
  }
  ngOnInit() {
    console.log("kkkkkkkkk", this.datavalue);

  }

  cancel() {
    console.log("console hit");
    this.dialogRef.close()
  }
  confirm() {
    this.apiService.getHttpDataPost('marketing/campaign-delete', {
      _id: this.datavalue._id,
      id: this.datavalue._id,
      ids: [
        this.datavalue._id
      ]
    }).subscribe({

      next: (response: any) => {
        this.flag= true
        console.log("response", response);
        this.matSnackBar.open(response.message, "Ok", {
          duration: 3000
        })
        this.dialogRef.close()

      },



      error: (error: any) => {
        console.log("error", error);
      }
    })

  }
}
