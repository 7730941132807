import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
 
import { ReactiveFormsModule } from '@angular/forms';
import { ListingAngular15Module } from 'listing-angular15';
import { DemoMaterialModule } from 'src/app/material/material.module';
import { FooterComponent } from 'src/app/layout/footer/footer.component';
import { HeaderComponent } from 'src/app/layout/header/header.component';
// import { LastLoginInfoComponent } from 'src/app/last-login-info/last-login-info.component';
import { last } from 'rxjs';
import { LastLoginInfoComponent } from 'src/app/last-login-info/last-login-info.component';
import { PieChartComponent } from 'src/app/pie-chart/pie-chart.component';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LastLoginInfoComponent,
    PieChartComponent
  ],
  imports: [
    CommonModule,
    DemoMaterialModule,
    ReactiveFormsModule,
    ListingAngular15Module,
  ],
  exports:[
    ListingAngular15Module,
    DemoMaterialModule,
    ReactiveFormsModule,
    HeaderComponent,
    FooterComponent,
    LastLoginInfoComponent,
    PieChartComponent
  ]
})
export class SharedModule { }
