import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WrongPageComponent } from './wrong-page/wrong-page.component';
import { LoginComponent } from './login/login.component';

import { AuthGuardService } from './services/auth-guard.service';
import { MainComponent } from './forget-password/main/main.component';
// import { PatientDashboardComponent } from './patient-dashboard/patient-dashboard.component';

const routes: Routes = [

  { path: '', component: LoginComponent, canActivate: [AuthGuardService] },
  
  { path: 'login', component: LoginComponent, canActivate: [AuthGuardService] },
  { path: 'forget-password', component: MainComponent },
  { path: 'image-gallery',  canActivate: [AuthGuardService],loadChildren: () =>import('./admin/imagegallery/imagegallery.module').then((m)=>m.ImagegalleryModule)},
  { path:'image-category',loadChildren:()=>import('./admin/image-category/image-category.module').then((m)=>m.ImageCategoryModule)},
 

  {
    path: 'blog-management',
    loadChildren: () =>
      import('./admin/blog-management/blog-management.module').then((m) => m.BlogManagementModule)
  },

  {
    path:'users',
    loadChildren:()=>
    import('./user/user.module').then((m)=>m.UserModule)
  },
  {
    path: 'landing-page',
    loadChildren: () =>
      import('./admin/opportunity/opportunity.module').then((m) => m.OpportunityModule)
  },

  {
    path: 'dashboard',
    loadChildren: () =>
      import('./admin/dashboard/dashboard.module').then((m) => m.DashboardModule)
  },
  {
    path: 'technician-dashboard/calendar-management',
    loadChildren: () =>
      import('./admin/calendar-management/calendar-management.module').then((m) => m.CalendarManagementModule)
  },

  {
    path: 'video-gallery', canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./admin/videogalley/videogalley.module').then((m) => m.VideogalleyModule)
  },

  {
    path:'video-category',
    loadChildren:()=> import('./admin/video-category/video-category.module').then((m)=>m.VideoCategoryModule)
  },
  {
    path: 'testimonial',
    loadChildren: () =>
      import('./admin/testimonial-management/testimonial-management.module').then((m) => m.TestimonialManagementModule)
  },
  {
    path: 'team-management',
    loadChildren: () =>
      import('./admin/team-manageemnt/team-manageemnt.module').then((m) => m.TeamManageemntModule)
  },
  {
    path: 'my-account',
    loadChildren: () =>
      import('./my-account/my-account.module').then((m) => m.MyAccountModule)
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./reset-password/reset-password.module').then((m) => m.ResetPasswordModule)
  },
  {
    path: 'forget-password',
    loadChildren: () =>
      import('./forget-password/forget-password.module').then((m) => m.ForgetPasswordModule)
  },
  {
    path: 'role-management',
    loadChildren: () =>
      import('./role-management/role-management.module').then((m) => m.RoleManagementModule)
  },
  {
    path: 'post-management',
    loadChildren: () =>
      import('./admin/social-advo/social-advo.module').then((m) => m.SocialAdvoModule)
  },
  {
    path: 'test/yt',
    loadChildren: () =>
      import('./yt/yt.module').then((m) => m.YtModule)
  },
  {
    path: 'marketing-dashboard',
    loadChildren: () =>
      import('./marketing-dashboard/marketing-dashboard.module').then((m) => m.MarketingDashboardModule)
  },
  {
    path: 'social-advo-dashboard',
    loadChildren: () =>
      import('./social-advo-dashboard/social-advo-dashboard.module').then((m) => m.SocialAdvoDashboardModule)
  },
  {
    path: 'technician-dashboard',
    loadChildren: () =>
      import('./technician-dashboard/technician-dashboard.module').then((m) => m.TechnicianDashboardModule)
  },
  {
    path: 'patient-dashboard',
    loadChildren: () =>
      import('./patient-dashboard/patient-dashboard.module').then((m) => m.PatientDashboardModule)
  },
  {
    path: 'banner',
    loadChildren: () =>
      import('./admin/manage-banner/manage-banner.module').then((m) => m.ManageBannerModule)
  },
  {
    path: 'campaign-list',
    loadChildren: () =>
      import('./admin/manage-campain/manage-campain.module').then((m) => m.ManageCampainModule)
  },
  {
    path: 'report',
    loadChildren: () =>
      import('./admin/report/report.module').then((m) => m.ReportModule)
  },
  {
    path: 'location',
    loadChildren: () =>
      import('./admin/location/location.module').then((m) => m.LocationModule)
  },
  {
    path: 'service-management',
    loadChildren: () =>
      import('./admin/service/service.module').then((m) => m.ServiceModule)
  },
  {
    path: 'website-management',
    loadChildren: () =>
      import('./admin/website-management/website-management.module').then((m) => m.WebsiteManagementModule)
  },
  {
    path:'contact',
    loadChildren:()=> import('./admin/contect/contect.module').then((m)=>m.ContectModule)
  },
  {
    path:'calender',
    loadChildren:()=> import('./admin/calender/calender.module').then((m)=>m.CalenderModule)
  },
 {
    path:'**',component: LoginComponent,
  },
 


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
