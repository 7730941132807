import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiservicesService } from 'src/app/services/apiservices.service';
import { environment } from 'src/environments/environment';
import { CommonPreviewModalComponent } from '../../Common-components/common-preview-modal/common-preview-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.css']
})
export class ServiceListComponent {


  public tabileCount:any = 0
  public libdata:any={}

  public tabiledata:any=[]

  
  public date_search_source: any = "users"
  public tablename = "Service List";
  public tabledata_detail_skip:any = ['_id','__v','updated_on','created_on']
  public deleteendpoint = "service/service-delete";
  public updateendpoint = "service/service-update"
  public editroute = "service-management/edit-service";
  public date_search_endpoint: any = "intake/assaylist"
  public api_url_for_managebanner = environment.api_url
  searchendpoint = "service/service-list"
  sortdata: any = {type: "desc",field: "updated_on",options: ["name","priority", "status"],};
  limitcond: any = {limit: 10,skip: 0,pagecount: 1,};
  public datacollection: any = "service/service-list";
  public updatetable: boolean = false;
  public jwttokenformanagebanner = ''
  
  public modify_header_array: any = {
    image: 'Image',
    name: 'Name',
    description: 'Description',
    priority:'Priority',
    status: 'Status',
    updated_on: 'Updated On'
  }

  statusarray: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];

  search_settings: any = {
    datesearch: [
      // {
      //   startdatelabel: 'Search By Created On Start Date',
      //   enddatelabel: 'Search By Created On End Date',
      //   submit: 'Search',
      //   field: 'created_on',
      // },
      {
        startdatelabel: 'Search By Updated On Start Date',
        enddatelabel: 'Search By Updated On End Date',
        submit: 'Search',
        field: 'updated_on',
      }
    ],
    textsearch: [
      {
        label: "Search By Name", field: 'name',
      }
    ],

    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.statusarray },
    ],

  }
  
  constructor(public activateRoute: ActivatedRoute,private apiservice: ApiservicesService, public router: Router, public dialog: MatDialog){}

  ngOnInit(){
    this.listConfigarationCreate()
    this.fetchRouteData()
  }


  fetchRouteData(){
    this.activateRoute.data.subscribe({
      next: (response: any) => {

        this.tabiledata = response.data.results.res

        console.log(this.tabiledata);
        


        let obj = {
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "searchcondition": {},
          "sort": {
            "type": "desc",
            "field": "created_on"
          },
          "project": {},
          "token": ""
        }

        this.apiservice.getHttpDataPost('service/service-list-count', obj ).subscribe({
          next:(response:any)=>{
            this.tabileCount = response.count
          },
          error:(error:any)=>{

          }
        })
      },
      error(err) {
      },
    })
  }



  listConfigarationCreate(){

    this.libdata={
      basecondition: {},
      detailview_override: [
          { key: "name", val: "name" },
          { key: "Address", val: "Address" },
          { key: "website_name", val: "website_name" },
      ],
      hideeditbutton: false,
      hidedeletebutton: false,
      hideviewbutton: true,
      hidestatustogglebutton: false,
      hidemultipleselectbutton: false,
      hideaction: false,
      updateendpoint:'service/service-status-change',
      updateendpointmany: "service/service-status-change",
      deleteendpointmany: "service/service-delete",
      tableheaders:  ["image", "name","description","priority", "status", "updated_on"],
      colpipes: [
        { type: 'datetime', col: 'created_on', format: 'MMMM D YYYY, hh:mm A' },
        { type: 'datetime', col: 'updated_on', format: 'MMMM D YYYY, hh:mm A' }
      ],
      colclasses: {cdt: {classname: 'created_on',},},
      custombuttons: [
        {
          label: 'Preview',
          type: 'listner',
          id: 'preview_btn',
          tooltip: 'Preview',
          name: 'preview_btn',
          classname: 'previewButton',
          previewlist: [
            'image',
            'name',
            'description',
            'priority',
            'status',
            'updated_on'
          ],
        },
      ],
      }
    }


  listenLiblistingChange(data: any = null){

    console.log(data);
    

    if (
      data.action == 'custombuttonclick' &&
      data.custombuttonclick?.btninfo?.id == 'preview_btn' &&
      data.custombuttonclick.data
    ) {
      const dialogRef = this.dialog.open(CommonPreviewModalComponent, {
        panelClass: 'custom-modalbox',
        data: {
          key: data.custombuttonclick.btninfo.previewlist,
          value: data.custombuttonclick.data
        },
      });
    }

  }
  onLiblistingButtonChange(val:any){

  }



  addPageRoute(){
    this.router.navigateByUrl(`/service-management/add-service`);
  }
}
