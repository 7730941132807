import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiservicesService } from 'src/app/services/apiservices.service';
import { bookingdetails } from 'src/app/technician-dashboard/google-calendar/google-calendar.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  public login_user_details: any = JSON.parse(this.cookieService.get('login_user_details'))
  public update_google_detals: any = {}
  public loader: boolean = false
  classToggled = false;
  public adminFlag: boolean = false
  public marketFlag: boolean = false
  public technicianFlag: boolean = false
  public socialAdvoFlag: boolean = false

  public toggleNav() {
    console.log('aaaa');
    this.classToggled = !this.classToggled;
  }

  constructor(public router: Router, public dialog: MatDialog, public matSnackBar: MatSnackBar, private apiservice: ApiservicesService, private cookieService: CookieService) { }


  ngOnInit() {

    console.log("hodol=====>", this.login_user_details.userinfo.user_type.includes('is_admin'));

    if (this.login_user_details.userinfo.user_type.includes('is_marketing_agency') === true) {
      this.marketFlag = true
      console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", this.marketFlag)
    }
    else if (this.login_user_details.userinfo.user_type.includes('is_user_patient') === true) {
      this.marketFlag = true
      console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", this.marketFlag)
    } else if (this.login_user_details.userinfo.user_type.includes('is_social_advo') === true) {

      this.socialAdvoFlag = true

    } else if (this.login_user_details.userinfo.user_type.includes('is_technician') === true) {
      this.technicianFlag = true
      console.log("technicianFlag=======>", this.technicianFlag)
    } else
    // if(this.login_user_details.userinfo.user_type.includes('is_admin') === true)
    {
      this.adminFlag = true
      console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", this.adminFlag)
    }


    this.update_google_detals = JSON.parse(this.cookieService.get('update_google_detals'))

  }

  currentPath(): string {
    return this.router.url

  }
  calendarPath() {
    return this.router.url.includes("/calendar-management")
  }
  bloglist() {
    this.loader = true
    setTimeout(() => {
      this.loader = false
    }, 1000);
    this.router.navigateByUrl(`/blog-management`);
  }

  blogCategoryRoute(){
    this.loader = true
    setTimeout(() => {
      this.loader = false
    }, 1000);
    this.router.navigateByUrl(`/blog-management/blog-category`);
  }

  imglist() {
    this.loader = true
    setTimeout(() => {
      this.loader = false
    }, 1000);
    this.router.navigateByUrl(`/image-gallery`);

  }
  videolist() {
    this.router.navigateByUrl(`/video-gallery`);

  }

  videoCategorylistRoute() {
    this.router.navigateByUrl(`/video-category`);

  }

  imageCategory(){
    this.router.navigateByUrl('/image-category')
  }

  calenderRoute(){
    const userCookie = JSON.parse(this.cookieService.get('login_user_details'))
    if(!userCookie.userinfo.calendar_id){
        this.router.navigateByUrl('calender/create-new-availability')
        return
      }else{
        this.router.navigateByUrl('/calender')
      }

  }
 



  dashboard() {
    this.loader = true
    setTimeout(() => {
      this.loader = false
    }, 1000);
    this.router.navigateByUrl(`/dashboard`);
  }
  users() {
    this.loader = true
    setTimeout(() => {
      this.loader = false
    }, 1000);
    this.router.navigateByUrl(`/users`);
  }

  roleManagement() {
    this.loader = true
    setTimeout(() => {
      this.loader = false
    }, 1000);
    this.router.navigateByUrl(`/role-management`);
  }

  opportunity() {
    this.router.navigateByUrl(`/landing-page`)
  }
  manageBanner() {
    this.router.navigateByUrl(`/banner`)
  }
  campaign() {
    this.router.navigateByUrl(`/campaign-list`)
  }
  calaendar() {
    this.router.navigateByUrl(`technician-dashboard/calendar-management/event-list`)
  }

  testimonial() {
    this.router.navigateByUrl(`/testimonial`)
  }

  testimonialRequest() {
    this.router.navigateByUrl(`/testimonial/request-testimonial`);

  }

  // << ----------- Dynamic Navigation ---------- >>


  goToPage(pageName: string) {
    this.router.navigateByUrl(pageName)
  }


  // << ----------- Dynamic Navigation ---------- >>




  burgerMenuClick() {
    var elements = document.getElementsByClassName("nav-listwrapper");

    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.toggle("mobileNav");
    }

  }
  teamManagement() {
    this.router.navigateByUrl(`/team-management`)

  }
  locationManagement() {
    this.router.navigateByUrl(`/location`)

  }
  serviceManagement() {
    this.router.navigateByUrl('/service')
  }

  contectList() {
    this.router.navigateByUrl(`/contact`)

  }

  websiteManagement() {
    this.router.navigateByUrl(`/website-management/website-list`)
  }

  socialddvo() {
    this.router.navigateByUrl(`/social-advo`)

  }

  Marketingdashboard() {
    this.loader = true
    setTimeout(() => {
      this.loader = false
    }, 1000);
    this.router.navigateByUrl(`/marketing-dashboard`);

  }
  socialAdvoDashboard() {
    this.loader = true
    setTimeout(() => {
      this.loader = false
    }, 1000);
    this.router.navigateByUrl(`/social-advo-dashboard`);

  }
  technicianDashboard() {
    this.loader = true
    setTimeout(() => {
      this.loader = false
    }, 1000);
    this.router.navigateByUrl(`/technician-dashboard`);

  }
  Userslist() {
    this.router.navigateByUrl(`/marketing-dashboard/users`);
  }
  Conversioncount() {
    this.router.navigateByUrl("marketing-dashboard/report/conversion-report")
  }
  Clickcount() {
    this.router.navigateByUrl("marketing-dashboard/report/click-report")
  }

  Adminconversioncount() {
    this.router.navigateByUrl("report/conversion-report")
  }
  Adminclickcount() {
    this.router.navigateByUrl("report/click-report")
  }
  Marketingtestimonial() {
    this.router.navigateByUrl(`/patient-dashboard/patient-testimonial`);
  }

  public myAccount() {
    console.log("test ===");

    this.router.navigateByUrl(`/my-account/account-info/${this.login_user_details.userinfo._id}`);

  }


  public changePass() {
    this.apiservice.getHttpDataPost("user/send-change-password-otp", { email: this.login_user_details.userinfo.email }).subscribe({
      next: (response: any) => {
        console.log("this is change password data", response);
        if (response.status === "success") {

          this.matSnackBar.open(response.message, "ok", {
            duration: 3000
          })

          this.router.navigateByUrl(`/reset-password`)
        }

      }
    })


  }


  public logout() {
    console.log("this is logout function data", this.login_user_details);

    this.apiservice.getHttpData(`user/logout/${this.login_user_details.userinfo.email}`).subscribe({
      next: (response: any) => {
        console.log("this is log out response", response);
        if (response.status === "success") {

          // this.cookieService.deleteAll('login_user_details')
          // this.cookieService.deleteAll('loggedin_user')
          // this.cookieService.deleteAll('update_google_detals')
          document.cookie = "update_google_detals=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie = "login_user_details=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie = "loggedin_user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

          let cookieVal = this.getCookieByName('login_user_details')
          if (cookieVal) {
            if (cookieVal.includes('userinfo')) {
              document.cookie = `login_user_details=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
              document.cookie = `loggedin_user=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
            }
          }

          this.matSnackBar.open("Logout Successfully", "Ok", {
            duration: 3000
          });
          this.router.navigateByUrl('/')

        }
      }
    })
  }


  getCookieByName(name: string) {
    const value = document.cookie;
    if (value.includes(name)) {
      console.log("value====>", value)
      let userVal = value.split(`${name}=`)[1]?.split(';')[0]
      console.log("userVal=====>", userVal)
      if (userVal) {
        return userVal
      } else {
        return null
      }
    } else {
      return null
    }
  }


}

