import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { ApiservicesService } from '../services/apiservices.service';
// import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { OtpVerificationComponent } from '../otp-verification/otp-verification.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  public loginForm: FormGroup;
  public loader: boolean = false;
  public not_Varifed: boolean = false
  private userIp: string = 'false';
  private unsubscriber: Subject<void> = new Subject<void>();
  public errors: any
  public loginButtonStatus: boolean = false






  hide = true;
  constructor(private apiService: ApiservicesService, private cookieService: CookieService, public router: Router, public matSnackBar: MatSnackBar, public dialog: MatDialog) {
    this.loginForm = new FormGroup({
      email: new FormControl(''),
      password: new FormControl(''),
    })
  }
  ngOnInit() {
    history.pushState(null, '');
    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
    });

    this.apiService.getHttpData('user/build-connection').subscribe({
      next: (getIpResp) => {
      }
    })


  }



  handleInputChange(data: any) {
    if (data.target.name === "email") {
      this.errors = { ...this.errors, email: '' }
    }

    if (data.target.name === "password") {
      this.errors = { ...this.errors, password: '' }
    }

    if (data.key === "Enter") {
      this.login()
    }
  }



  login() {

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{8,}$/;

    this.loginButtonStatus = true

    this.errors = {}

    if (this.loginForm.value.email === "") {
      this.errors = { ...this.errors, email: "Email is required" }
    }else if (!emailPattern.test(this.loginForm.value.email)){
      this.errors = { ...this.errors, email: "Email is not valid" }
    }


    if (this.loginForm.value.password === "") {
      this.errors = { ...this.errors, password: "Password in required" }
    }else if (!passwordPattern.test(this.loginForm.value.password)){
      this.errors = { ...this.errors, password: "Use valid Passward" }
    }
    if(Object.keys(this.errors).length < 1) {


      this.loader = true;

      this.apiService.getHttpDataPost(environment.stage == "local" ? "user/login" : "user/login-new", this.loginForm.value).subscribe({


        next: (response: any) => {


          if (response.status === "success") {
            this.cookieService.set('login_user_details', JSON.stringify(response.results), { path: '/', });
            this.cookieService.set('loggedin_user', 'true', { path: '/', });
            this.loader = false;

            this.matSnackBar.open(response.message, "Ok", {
              duration: 3000
            })



            if (response.results.userinfo.last_login_time === null) {
              this.router.navigateByUrl(`/reset-password`)
            }

            else {

              if (response.results.userinfo.user_type[0] === 'is_admin') {
                this.router.navigateByUrl(`/dashboard`);

              }


              else if (response.results.userinfo.user_type[0] === 'is_user_patient') {
                this.router.navigateByUrl(`/patient-dashboard`);
              }
              else if (response.results.userinfo.user_type[0] === 'is_marketing_agency') {
                this.router.navigateByUrl('/marketing-dashboard');

              } else if (response.results.userinfo.user_type[0] === 'is_social_advo') {

                this.router.navigateByUrl('/social-advo-dashboard')

              } else if (response.results.userinfo.user_type[0] === 'is_technician') {
                this.router.navigateByUrl('/technician-dashboard')
              }
            }
          }
        },


        error: (error: any) => {
          this.loader = false;
          if (error?.results?.user && error?.results?.user === 'inactive') {
            this.matSnackBar.open(error.results.message, "Ok", { duration: 3000 })
          }
          else {
            this.matSnackBar.open(error.message, "Ok", { duration: 3000 })
          }
          if (error.not_Varifed === true) {
            this.apiService.getHttpData("user/request-verification-code?username=" + this.loginForm.value.email).subscribe((res: any) => {
          })

          const dialogRef = this.dialog.open(OtpVerificationComponent, {

            data: { "not_Varifed": error.not_Varifed, "email": this.loginForm.value.email.split(" ").join(""), login: this.login },

          })

          this.loginButtonStatus = true

            dialogRef.afterClosed().subscribe((result) => {
              this.login()
            })
          }
        }
      })
    }
  }





  handlenter(e: any) {
    this.hide = true
    console.log("enter click", e.key);

    if (e.key === "Enter") {
      this.login()
    }

  }

  handlevisibl(event: any) {
    event.preventDefault()
    this.hide = !this.hide
  }


}
