<div class="login_bodys">
  <div class="login_mains">
    <div class="login_logos"></div>
    <div class="forget_forms">

      <form class="form_wrps" autocomplete="off" name="loginForm" [formGroup]="loginForm">

        <p>Forgot Password</p>

        <div class="email_cls">

          <div class="iconwrapper"><i class="fa fa-user-circle-o" aria-hidden="true"></i></div>

          <mat-form-field class="login-full-width">
            <input matInput placeholder="Email" name="email" formControlName="email" required class="form_wrp-email" />
          </mat-form-field>

          <ng-container *ngIf="(chekSubmition && !loginForm.controls['email'].valid)  || loginForm.controls['email'].touched && loginForm.controls['email'].value == ''">
            <span class="error">Email is Required.</span>
          </ng-container>

        </div>

        <h1><a [routerLink]="['/']">Back to Login</a></h1>

      </form>

      <button class="submit" (click)="forgoSubmit()">Submit</button>
      <mat-progress-bar *ngIf="loader" mode="indeterminate" class="loader_wrp"></mat-progress-bar>
    </div>

    <div class="login_form_shasow">
      <img src="https://all-frontend-assets.s3.amazonaws.com/beto_paredes_nest/login/shadow_layer.webp" alt="" />
    </div>


  </div>
</div>