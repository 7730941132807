import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiservicesService } from 'src/app/services/apiservices.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.css']
})
export class LocationListComponent {


  public tabiledata:any=[]
  public tabileCount:any = 0
  public libdata:any={}



  public api_url_for_managebanner = environment.api_url
  public deleteendpoint = "loation/spiritual-wisdom-delete";
  public updateendpoint = "loation/location-update"
  public date_search_source: any = "users"
  public date_search_endpoint: any = "intake/assaylist"
  public tablename = "Location List";
  public editroute = "location/edit";
  public datacollection: any = "loation/location-list";
  public searchendpoint = "loation/location-list"
  public updatetable: boolean = false;
  public jwttokenformanagebanner = ''


  public  limitcond: any = {limit: 10,skip: 0,pagecount: 1,};
  public tabledata_detail_skip:any = ['_id','__v','updated_on','created_on']
  public sortdata: any = {type: "desc",field: "updated_on",options: ["name", "status", "updated_on"],};
  public statusarray: any = [{ val: 1, name: "Active" },{ val: 0, name: "Inactive" },];
  // public tabledata_header_skip: any = ["_id", "__v",];
  public modify_header_array: any = {
    name: 'Name',
    Address:'Address',
    web_name: 'Website Name',
    status: 'Status',
    zip:'Zip',
    // created_on: 'Created On',
    updated_on: 'Updated On'
  }

  constructor(public activateRoute: ActivatedRoute,private apiservice: ApiservicesService, public router: Router){}

  ngOnInit(){
    this.listConfigarationCreate()
    this.fetchRouteData()
  }



  fetchRouteData(){
    this.activateRoute.data.subscribe({
      next: (response: any) => {

        this.tabiledata = response.data.results.res


        let obj = {
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "searchcondition": {},
          "sort": {
            "type": "desc",
            "field": "updated_on"
          },
          "project": {},
          "token": ""
        }

        this.apiservice.getHttpDataPost('loation/location-list-count', obj ).subscribe({
          next:(response:any)=>{
            this.tabileCount = response.count
          },
          error:(error:any)=>{

          }
        })
      },
      error(err) {
      },
    })
  }


  listConfigarationCreate(){

  this.libdata={
    basecondition: {},
    detailview_override: [
        { key: "name", val: "name" },
        { key: "Address", val: "Address" },
        { key: "website_name", val: "website_name" },
    ],
    updateendpoint:'loation/status-change',
    hideeditbutton: false,
    hidedeletebutton: false,
    hideviewbutton: false,
    hidestatustogglebutton: false,
    hidemultipleselectbutton: false,
    hideaction: false,
    updateendpointmany: "loation/spiritual-wisdom-delete",
    deleteendpointmany: "/loation/spiritual-wisdom-delete",
    tableheaders:  ["name","Address","web_name", "zip", "status","updated_on"],
    colpipes: [
      // { type: 'datetime', col: 'created_on', format: 'MMMM D YYYY, hh:mm A' },
      { type: 'datetime', col: 'updated_on', format: 'MMMM D YYYY, hh:mm A' }
    ],
    colclasses: {cdt: {classname: 'updated_on',},},
    custombuttons: [],
    }
  }




  search_settings: any = {
    datesearch: [
      // {
      //   startdatelabel: 'Search By Created On Start Date',
      //   enddatelabel: 'Search By Created On End Date',
      //   submit: 'Search',
      //   field: 'created_on',
      // },
      {
        startdatelabel: 'Search By Updated On Start Date',
        enddatelabel: 'Search By Updated On End Date',
        submit: 'Search',
        field: 'updated_on',
      }
    ],
    textsearch: [
      {
        label: "Search By Name", field: 'name',
      },
      {
        label: "Search By Website Name", field: 'web_name',
      },
      {
        label: "Search By Zip", field: 'zip',
      },
    ],

    selectsearch: [
      { label: 'Search By Status', field: 'status', values: this.statusarray },
    ],

  }





  listenLiblistingChange(Val:any){

  }
  onLiblistingButtonChange(val:any){

  }


  addPageRoute(){
    this.router.navigateByUrl(`/location/add`);
  }

  
  

}
