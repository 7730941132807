import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private cookieService: CookieService) { }

  public loggedInNavigation(): any {
    // console.log("cookieService", JSON.parse(this.cookieService.get('login_user_details')))
    const userType: any = this.cookieService.get('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')): ''
   console.log("userrr=>",userType)
    if (userType?.userinfo?.user_type[0]==='is_admin') return '/dashboard'
    // if (userType?.userinfo?.user_type=== 'is_rep') return '/rep-dashboard'
    return null
  }
  
  public isAdminAuthenticated(): boolean {
    // console.log("this.cookieService", JSON.parse(this.cookieService.get('login_user_details')))
    const userType: any = this.cookieService.get('login_user_details') ? JSON.parse(this.cookieService.get('login_user_details')): ''
    console.log("userrr111111=>",userType)

    if (userType?.userinfo?.user_type ==='is_admin') return true
    return false
  }

}
