<div class="preview_main_main">
    <button mat-fab color="primary" matTooltip="Close" (click)="closePreview()"
        aria-label="Example icon button with a delete icon">
        <mat-icon>close</mat-icon>
    </button>
    <!-- <button (click)="closePreview()">x</button> -->
    <div class="preview_main">
        <h1>Preview</h1>
        <div class="preview_list" *ngIf="!dataOrigin && !camapaignOrigin && !clickcount && !usersdata && !adminclickcount ">
            <div class="preview_list_text">
                <p>Event Title:</p>
                <p>{{ datavalue.event_title }}</p>
            </div>
            <div class="preview_list_text">
                <p>User Email:</p>
                <p>{{ datavalue.useremail }}</p>
            </div>
            <div class="preview_list_text">
                <p>Week Day:</p>
                <p>{{ day }}</p>
            </div>
            <div class="preview_list_text">
                <p>Timespan:</p>
                <p>{{ datavalue.timespan }}</p>
            </div>
            <div class="preview_list_text">
                <p>Start Date:</p>
                <p>{{ startDate }}</p>
            </div>
            <div class="preview_list_text">
                <p>End Date:</p>
                <p>{{ endDate }}</p>
            </div>
            <div class="preview_list_text">
                <p>Start Time:</p>
                <p>{{ datavalue.start_time }}</p>
            </div>
            <div class="preview_list_text">
                <p>End Time:</p>
                <p>{{ datavalue.end_time }}</p>
            </div>
            <div class="preview_list_text">
                <p>Description:</p>
                <p [innerHTML]="datavalue.description"></p>
            </div>
            <div class="preview_list_text">
                <p>Time Zone:</p>
                <p>{{ datavalue.timezone }}</p>
            </div>
        </div>

        <div class="preview_list" *ngIf="dataOrigin">
            <div class="preview_list_text">
                <p>Thumbnail:</p>
                <span>
                    <img [src]="
              this._sanitizer.bypassSecurityTrustResourceUrl(
                datavalue.thumbnail_img ? datavalue.thumbnail_img : 'N/A'
              )
            " alt="" />
                </span>
            </div>
            <div class="preview_list_text">
                <p>Landing page:</p>
                <p>{{ datavalue.landingpage_name }}</p>
            </div>
            <div class="preview_list_text">
                <p>Description:</p>
                <p>{{ datavalue.description }}</p>
            </div>
            <div class="preview_list_text">
                <p>Domain Url:</p>
                <p>{{ datavalue.domain_url }}</p>
            </div>
            <div class="preview_list_text">
                <p>Priority:</p>
                <p>{{ datavalue.priority }}</p>
            </div>
            <div class="preview_list_text">
                <p>Status:</p>
                <p>{{ datavalue.status == 1 ? "Active" : "Inactive" }}</p>
            </div>
            <div class="preview_list_text">
                <p>Created On:</p>
                <p>{{ created_on }}</p>
            </div>
        </div>

        <div class="preview_list" *ngIf="camapaignOrigin">
            <div class="preview_list_text">
                <p>Campaigns Name:</p>
                <p>{{ datavalue.campaign_name }}</p>
            </div>
            <div class="preview_list_text">
                <p>Landing Page:</p>
                <p>{{ datavalue.landing_data[0].landingpage_name }}</p>
            </div>
            <div class="preview_list_text">
                <p>Description:</p>
                <p>{{ datavalue.landing_data[0].description }}</p>
            </div>
            <div class="preview_list_text">
                <p>Status:</p>
                <p>{{ datavalue.status === 1 ? "Active" : "Inactive" }}</p>
            </div>
            <div class="preview_list_text">
                <p>Created On:</p>
                <p>{{ created_on }}</p>
            </div>
        </div>
        <div class="preview_list" *ngIf="clickcount">
            <div class="preview_list_text">
                <p>Campaigns Name:</p>
                <p>{{ datavalue.campaign_name }}</p>
            </div>
            <div class="preview_list_text">
                <p>Landing Page:</p>
                <p>{{ datavalue.landing_page_name || datavalue.landing_name}}</p>
            </div>

            <div class="preview_list_text">
                <p>Created On:</p>
                <p>{{ created_on }}</p>
            </div>
        </div>
        <div class="preview_list" *ngIf="adminclickcount">
            <div class="preview_list_text">
                <p>Name:</p>
                <p>{{ datavalue.name }}</p>
            </div>
            <div class="preview_list_text">
                <p>Email:</p>
                <p>{{ datavalue.email}}</p>
            </div>

            <div class="preview_list_text">
                <p>Created On:</p>
                <p>{{ created_on }}</p>
            </div>
        </div>
        <div class="preview_list" *ngIf="usersdata">
            <div class="preview_list_text">
                <p>Name:</p>
                <p>{{ datavalue.name }}</p>
            </div>
            <div class="preview_list_text">
                <p>Email:</p>
                <p>{{ datavalue.email }}</p>
            </div>

            <div class="preview_list_text">
                <p>Phone:</p>
                <p>{{ datavalue.phone }}</p>
            </div>
            
            <div class="preview_list_text">
                <p>Address:</p>
                <p>{{  datavalue.address }}</p>
            </div>
            <div class="preview_list_text">
                <p>State:</p>
                <p>{{  datavalue.state }}</p>
            </div>
            <div class="preview_list_text">
                <p>City:</p>
                <p>{{ datavalue.city }}</p>
            </div>
            <div class="preview_list_text">
                <p>Zip:</p>
                <p>{{  datavalue.zip }}</p>
            </div>
            <div class="preview_list_text">
                <p>Status:</p>
                <p>{{ datavalue.status == 1 ? "Active" : "Inactive" }}</p>
            </div>
            <div class="preview_list_text">
                <p>Joined On:</p>
                <p>{{ created_on }}</p>
            </div>

        </div>
        
    </div>
</div>