import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared-module/shared-module/shared-module.module';
import { FormsModule } from '@angular/forms';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { WrongPageComponent } from './wrong-page/wrong-page.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { PreviewComponent } from './admin/Common-components/preview/preview.component';

import { OtpVerificationComponent } from './otp-verification/otp-verification.component';
import { AudioRecordingService } from './services/audio-recording.service';
import { CustomeModalComponent } from './admin/Common-components/custome-modal/custome-modal.component';
// import { PatientDashboardComponent } from './patient-dashboard/patient-dashboard.component';
import { DeleteComponentComponent } from './admin/Common-components/delete-component/delete-component.component';
import { ComingSoonComponent } from './admin/Common-components/coming-soon/coming-soon.component';
import { LocationListComponent } from './admin/location/location-list/location-list.component';
import { LocationAddEditComponent } from './admin/location/location-add-edit/location-add-edit.component';
import { ContectListComponent } from './admin/contect/contect-list/contect-list.component';
import { ServiceListComponent } from './admin/service/service-list/service-list.component';
import { CommonPreviewModalComponent } from './admin/Common-components/common-preview-modal/common-preview-modal.component';
import { ConnectAccountsComponent } from './admin/social-advo/connect-accounts/connect-accounts.component';
// import { PieChartComponent } from './pie-chart/pie-chart.component';
// import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
// import { SharerMethod } from 'ngx-sharebuttons';
// import { ShareIconsModule } from 'ngx-sharebuttons/icons';


// import { HeaderComponent } from './layout/header/header.component';
// import { FooterComponent } from './layout/footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    WrongPageComponent,
    LoginComponent,
    PreviewComponent,
  
    OtpVerificationComponent,
       CustomeModalComponent,
      //  PatientDashboardComponent,
       DeleteComponentComponent,
       ComingSoonComponent,
       LocationListComponent,
       LocationAddEditComponent,
       ServiceListComponent,
       CommonPreviewModalComponent,
       ConnectAccountsComponent
      
      
    // FooterComponent
    
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    

    
    FormsModule,
    HttpClientModule,
    // ShareButtonsModule.withConfig({
    //   debug: true,
    //   sharerMethod: SharerMethod.Window
    // }),
    // ShareIconsModule,
    
  ],
  providers: [AuthGuardService, AuthService,AudioRecordingService],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
