import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-custome-modal',
  templateUrl: './custome-modal.component.html',
  styleUrls: ['./custome-modal.component.css']
})
export class CustomeModalComponent {
  constructor( @Inject(MAT_DIALOG_DATA) public data: any,private dialogRef: MatDialogRef<CustomeModalComponent>,public _sanitizer: DomSanitizer) {}
  public tabledatatalist: any = [];
  // public create_on:any
  // public updated_on:any

  ngOnInit() {
  }

  closeDialog() {
  
    this.dialogRef.close();
  }
}
