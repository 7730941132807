<div class="accountLinkmodal_wrp">
    <div class="accountLinkButton accountLinkButton_fb">
      <div class="icon_wrap" (click)="navigateAuth('facebook')">
        <i class="fa fa-facebook" aria-hidden="true"></i>
      </div>
      <!-- <button class="" > -->
        <button class="front pushable" (click)="navigateAuth('facebook')">Connect Facebook</button>
      <!-- </button> -->
    </div>
    <div class="accountLinkButton accountLinkButton_twt">
      <div class="icon_wrap" (click)="navigateAuth('twitter')">
        <span class="fa fa-twit"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg></span>
      </div>
  
      <!-- <button class="" > -->
        <button class="front pushable" (click)="navigateAuth('twitter')">Connect Twitter</button>
      <!-- </button> -->
    </div>
    <div class="accountLinkButton accountLinkButton_lnkdn">
      <div class="icon_wrap" (click)="navigateAuth('lnkdn')">
        <i class="fa fa-linkedin" aria-hidden="true"></i>
      </div>
  
      <!-- <button class="" > -->
        <button class="front pushable" (click)="navigateAuth('lnkdn')">Connect LinkedIn</button>
      <!-- </button> -->
    </div>
  </div>