import { Component, Input, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import Chart from 'chart.js/auto';
@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnChanges {
  public chart: any;
  public clickcoun:any = {}

  public chartValue: any = {}


  constructor(private _ngZone: NgZone) { }

  @Input() chartVal:any


  ngOnChanges(changes: SimpleChanges){
    console.log("changes=====>",changes);
    
    this.chartValue = changes['chartVal'].currentValue
    console.log("changesssssssssss=====>",this.chartValue);

  }
  ngOnInit() : void {
    this._ngZone.runOutsideAngular(() => {
      this.createChart();
    })

  }



  createChart(): void {
this.clickcoun = (this.chartValue?.clickcount - this.chartValue?.conversioncount)    
console.log('clickcoun=====>',this.clickcoun);

    this.chart = new Chart("MyChart", {
      type: 'pie', //this denotes tha type of chart

      data: {// values on X-Axis
        labels: ['Click', 'Conversion'],
        datasets: [{
          label: 'Count',
          data: [this.clickcoun, this.chartValue?.conversioncount],
          backgroundColor: [
            '#32a0de',
            '#0569ad',
          ],
          hoverOffset: 4
        }],
        
      },
      
      options: {
        aspectRatio: 2.5
      }

    });
  }
}
 

