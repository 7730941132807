<app-header></app-header>
<div class="mainwrapper">
    <div class="new-container">
      <div class="subwrapper">
        <div class="sub-title wrapper">
          <h2>Social Advo Tools</h2>
          
        </div>

        <div>
            <div class="socialBtnblock">
              <ng-container *ngFor="let profile of linkedAccountData">
                <div
                  class="social_profile"
                  [ngClass]="{
                    social_profile_fb: profile.appType === 'facebook',
                    social_profile_twt: profile.appType === 'twitter',
                    social_profile_lnkdn: profile.appType === 'linkedin'
                  }"
                >
                  <!-- <div class="social_profilewrp"> -->
                    <h2 class="profile_heading">{{ profile.appType }}</h2>
                    <div class="profile_imgsec">
                      <div class="profile_imgwrp">
                        <img [src]="profile.image" />
                      </div>
                    </div>
                    <div class="profile_namesec">
                      <p>{{ profile.name }}</p>
                      <section *ngIf="profile.userName">@{{ profile.userName }}</section>
                    </div>
                  <!-- </div> -->
                  <span
                    class="remove_iconwrp material-icons"
                    (click)="deleteProfile(profile.id)" matTooltip="Remove Account"
                    >close</span>
                </div>
              </ng-container>
      
              <div class="social_profile_addbtn">
                <button (click)="openConnectAccModal()" matTooltip="Connect Account">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div>
      
          </div>
        
      </div>
    </div>
  </div>
  <app-footer></app-footer>