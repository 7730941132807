
<app-header></app-header>
<div class="mainwrapper teammanagement_mainwrapper">
  <div class="new-container">
    <div class="subwrapper">


      <app-last-login-info></app-last-login-info>


      <div class="sub-title wrapper">
        <h2>Location List</h2>
        <button mat-button class="add_btn" (click)="addPageRoute()">Add Location</button>
      </div>


      <div class="package">
        <div class="list-style1">
          <div class="taxonomies_liststyle">

            <lib-listing class="taxonomies_liststyle"
              [datasource]="tabiledata" [modify_header_array]="modify_header_array"
              [date_search_source]="date_search_source" [sourcedata]="tablename"
              [detail_skip_array]="tabledata_detail_skip"
              [search_settings]="search_settings" 
              [deleteendpoint]="deleteendpoint" 
              [updateendpoint]="updateendpoint"
              [editroute]="editroute"
              [statusarr]="statusarray"
              [date_search_endpoint]="date_search_endpoint"
              [apiurl]="api_url_for_managebanner"
              [searchendpoint]="searchendpoint"
              [sortdata]="sortdata"
              [date_search_source_count]="tabileCount"
              [jwttoken]="jwttokenformanagebanner"
              [libdata]="libdata" 
              [limitcond]="limitcond"
              [date_search_source]="date_search_source"  
              [editroute]="editroute"   
              [datacollection]="datacollection"
              [updatetable]="updatetable" 
              (onLiblistingChange)="listenLiblistingChange($event)" 
              (onLiblistingButtonChange)="onLiblistingButtonChange($event)">
            </lib-listing>
            
            <!-- <div class="noFoundTextinner">
              <span>Oops !</span>
              <p>NO Record Found</p>
            </div> -->


          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
<!-- [skip]="tabledata_header_skip" -->
