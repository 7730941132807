import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private authService: AuthService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    console.log("next================>", next, state, this.router)

    if (next.routeConfig?.path == 'dashboard' && this.authService.isAdminAuthenticated()) return true
    // if (next.routeConfig?.path == 'rep-dashboard' && (this.authService.isRepAuthenticated() || this.authService.isRepAuthenticated())) return true
    // if (next.routeConfig?.path == 'customer-dashboard' && this.authService.isSocialAdvoAuthenticated()) return true
    if (next.routeConfig?.path == 'video-gallery' && this.authService.loggedInNavigation()) return true
    if (next.routeConfig?.path == 'image-gallery' && this.authService.loggedInNavigation()) return true

    if (next.routeConfig?.path == '') {
      const navigationRoute = this.authService.loggedInNavigation()
      console.log("navigationRoute==============>", navigationRoute)
      if (navigationRoute) this.router.navigateByUrl(navigationRoute);
      return true
    }
    
    this.router.navigateByUrl('/');
    return false;
  }
}
