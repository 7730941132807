export const environment = {

  production: true,
  stage: 'development',
  baseHref: '/',
  domain_url: '/',
  api_url: "https://dev.api.screenforcancer.life/",
  //  api_url: 'http://localhost:3525/',
  fileuploadbaseurl:"https://dev.api.screenforcancer.life/",
  without_base_url:"https://i526y91jwg.execute-api.us-east-2.amazonaws.com/dev/",
  preview_url:"https://awsbackend-dev-patient-files-test.s3.amazonaws.com/"

};
