import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiservicesService } from 'src/app/services/apiservices.service';
import { Location } from "@angular/common";
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';
// import moment from 'moment';


@Component({
  selector: 'app-last-login-info',
  templateUrl: './last-login-info.component.html',
  styleUrls: ['./last-login-info.component.css']
})
export class LastLoginInfoComponent {
   
  public user_login_details:any=null
  public last_login:any;

  constructor(private apiservice: ApiservicesService, public cookieService: CookieService, public matSnackBar: MatSnackBar, public router: Router, public activateRoute: ActivatedRoute, private location: Location) { 
    this.user_login_details = JSON.parse(this.cookieService.get('login_user_details'))
  }

  ngOnInit() {
    

    this.last_login=this.user_login_details.lastLoginTime ===null || this.user_login_details.lastLoginTime ===0? "N/A":moment(this.user_login_details.lastLoginTime).format('MMMM Do YYYY, h:mm:ss A');
    let time = moment().valueOf()
    // this.last_login=moment(this.user_login_details.lastLoginTime).format('MMMM Do YYYY, h:mm:ss a');
    console.log("this is lat login",this.last_login);
    console.log("this is lat login----",this.user_login_details.lastLoginTime);
    
  }

}
