import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SocialadvoaccountserviceService {

  constructor() { }

  generateProfileDataSet(data: any) {
    let linkedAccountData: any = [];

    data?.forEach((x: any) => {
      console.log(x, "x");
      // console.log('xxxxxxxxxxxxxxxxx>', x);
      let profileData;

      if (x.social_media_app == 'facebook') {
        
        
        profileData = {
          id: x._id,
          name:
            x.profile_data.name,
          appType: x.social_media_app,
          userMail: x.user_email,
          image: x.profile_data?.picture
            ? x.profile_data.picture
            : 'https://d37pbainmkhx6o.cloudfront.net/divine-infinity-profile-images/1691477743102profile_img.png',
        };
      }
      if (x.social_media_app == 'linkedin') {
        
        
        profileData = {
          id: x._id,
          name:
            x.profile_data.name,
          appType: x.social_media_app,
          userMail: x.user_email,
          image: x.profile_data?.picture
            ? x.profile_data.picture
            : 'https://d37pbainmkhx6o.cloudfront.net/divine-infinity-profile-images/1691477743102profile_img.png',
        };
      }
      if (x.social_media_app == 'twitter') {
        profileData = {
          id: x._id,
          name: x.profile_data.name,
          appType: x.social_media_app,
          userMail: x.user_email,
          userName: x.profile_data.username,
          image: x.profile_data?.profile_image_url
            ? x.profile_data.profile_image_url
            : 'https://d37pbainmkhx6o.cloudfront.net/divine-infinity-profile-images/1691477743102profile_img.png',
        };
      }

      linkedAccountData.push(profileData);
    });
    console.log('linkedAccountData------------->', linkedAccountData);

    return linkedAccountData;
  }
}
